import React from 'react';
import { Route, Switch } from 'react-router';
import { CookiesProvider, useCookies } from 'react-cookie';
import composeFunctions from 'core/utils/composeFunctions';
import withAppContext from 'core/hoc/withAppContext';
import reduxProvider from 'core/hoc/reduxProvider';

import NewMessageChannelForm from 'screens/Messages/OmniChannelForms/NewMessageChannel';
import OmniChannelScreen from 'screens/Messages/OmniChannelScreen';

import { OmniChannelRoutesProps } from './types';
import EditMessageChannel from 'screens/Messages/OmniChannelForms/EditMessageChannel';
import NewMessageChatForm from 'screens/Messages/OmniChannelForms/NewMessageChatForm';
import NewTicketChannel from 'screens/Messages/OmniChannelForms/NewTicketChannel';
import EditTicketChannel from 'screens/Messages/OmniChannelForms/EditTicketChannel';
import NewTicketBySchoolForm from 'screens/Messages/OmniChannelForms/NewTicketBySchool';

import * as S from './styles';
import NewMessageChat from 'screens/Messages/OmniChannelForms/NewMessageChat';
import launchDarkly from 'core/helper/launchDarkly';
import { flags } from 'core/constants/flags';
import ChatProvider from 'core/contexts/OmniChannel/Chat';
import UploadFilesProvider from 'core/contexts/UploadFiles';

const OmniChannelRoutes = ({
  appContext: { dataArea },
}: OmniChannelRoutesProps) => {
  const [cookies] = useCookies(['agendaedu_launch_darkly']);

  const activeFlags = cookies.agendaedu_launch_darkly?.active_flags;

  const hasChatLDFlag = launchDarkly.checkFlagActive(
    activeFlags,
    flags.NEW_CHAT
  );

  return (
    <S.Wrapper>
      <CookiesProvider>
        <Switch>
          <Route
            exact
            path={`/${dataArea}/messages`}
            component={OmniChannelScreen}
          />
          <Route
            exact
            path={`/${dataArea}/messages/channels/:channelId/chats/:chatId/messages`}
            component={OmniChannelScreen}
          />
          <Route
            exact
            path={`/${dataArea}/messages/channels/new`}
            component={NewMessageChannelForm}
          />
          <Route
            exact
            path={`/${dataArea}/messages/channels/:channelId/edit`}
            component={EditMessageChannel}
          />
          <Route
            exact
            path={`/${dataArea}/messages/channels/:channelId/new_message`}
            component={
              hasChatLDFlag
                ? (channelId: string) => (
                    <UploadFilesProvider>
                      <ChatProvider>
                        <NewMessageChat channelId={channelId} />
                      </ChatProvider>
                    </UploadFilesProvider>
                  )
                : NewMessageChatForm
            }
          />
          <Route
            exact
            path={`/${dataArea}/messages/channels/:channelId/new_ticket`}
            component={NewTicketBySchoolForm}
          />
          <Route
            exact
            path={`/${dataArea}/messages/channels/tickets/new`}
            component={NewTicketChannel}
          />
          <Route
            exact
            path={`/${dataArea}/messages/channels/:channelId/tickets/edit`}
            component={EditTicketChannel}
          />
        </Switch>
      </CookiesProvider>
    </S.Wrapper>
  );
};

export default composeFunctions(
  withAppContext,
  reduxProvider
)(OmniChannelRoutes);
