import actions from './actions';
import filterActions from '../filters/actions';
import createModuleReducer from '../storeConfig/createModuleReducer';

import {
  AlbumDetailsMediasResponse,
  AlbumDetailsResponse,
  AlbumFormResponse,
  AlbumListResponse,
  AlbumMedia,
  AlbumStudentProfileIncluded,
  AlbumsState,
  DeletedAlbumResponse,
  PhotoVisibilityStudentsByHeadquarterResponse,
  PhotoVisibilityStudentsResponse,
  ResultsActionDefault,
  SetDefaultSchoolTermProps,
  SetFilterProps,
} from './types';

export const INITIAL_STATE: AlbumsState = {
  isLoading: true,
  isLoadingFormClassrooms: true,
  isLoadingAlbumDetailsMedias: true,
  isUpdatingAlbumMedia: false,
  isUploadingMedias: false,
  isLoadingPhotoVisibilityStudentsByHeadquarter: false,
  isLoadingPhotoVisibilityStudents: false,
  albums: [],
  albumsIncluded: [],
  albumsMeta: null,
  albumDetails: null,
  albumDetailsIncluded: [],
  albumDetailsMedias: [],
  albumDetailsMediasIncluded: [],
  albumDetailsMediasPaginationLinks: null,
  photoVisibilityStudentsByHeadquarter: [],
  photoVisibilityStudentsByHeadquarterMeta: null,
  photoVisibilityStudents: [],
  filters: {
    name: '',
    situation: '',
    startDate: '',
    endDate: '',
    headquarterIds: [],
    educationalStageIds: [],
    classroomIds: [],
    schoolTermNames: [],
  },
  pagination: {
    links: null,
  },
  current: {
    sent_kind: 'classrooms',
    classroom_ids: [],
    classroom_with_student_profile_ids: {},
    persona_type: 'both',
    title: '',
    description: '',
  },
  formMeta: {
    classrooms: [],
    canEditClassrooms: true,
    hideSendTo: true,
    isSubmittingAlbum: false,
    hideRadio: false,
  },
};

export const fetchAlbums = (state: AlbumsState) => ({
  ...state,
  isLoading: true,
});

export const fetchFormClassroomsSuccess = (state: AlbumsState, action) => {
  const { data } = action;
  const classrooms = data.map((classroom) => ({
    ...classroom,
    options: classroom.options.map((option) => ({
      ...option,
      label: option.attributes.name,
      value: option.id,
    })),
  }));

  return {
    ...state,
    isLoadingFormClassrooms: false,
    formMeta: {
      ...state.formMeta,
      classrooms,
      select_options: {
        classroom_id: classrooms,
      },
    },
  };
};

export const fetchAlbumsSuccess = (
  state: AlbumsState,
  action: {
    data: AlbumListResponse['data'];
    included: AlbumListResponse['included'];
    meta: AlbumListResponse['meta'];
    links: AlbumListResponse['links'];
    isPagination: boolean;
  }
) => ({
  ...state,
  isLoading: false,
  albums: action.isPagination ? [...state.albums, ...action.data] : action.data,
  albumsIncluded: action.isPagination
    ? [...state.albumsIncluded, ...action.included]
    : action.included,
  albumsMeta: action.meta,
  pagination: { links: action.links },
});

export const setFilter = (state: AlbumsState, action: SetFilterProps) => ({
  ...state,
  isLoading: true,
  albums: [],
  albumsMeta: null,
  pagination: {
    links: null,
  },
  filters: { ...state.filters, ...action.filters },
});

export const handleError = (
  state: AlbumsState,
  action: ResultsActionDefault
) => ({
  ...state,
  isLoading: false,
  error: action.error,
  formMeta: {
    ...state.formMeta,
    isSubmittingAlbum: false,
  },
});

export const setDefaultSchoolTerm = (
  state: AlbumsState,
  action: SetDefaultSchoolTermProps
) => {
  const defaultSchoolTermName = action.activeSchoolTerm.find(
    (schoolTerm) => schoolTerm.attributes.status
  ).attributes.name;

  return {
    ...state,
    isLoading: true,
    filters: {
      ...state.filters,
      schoolTermNames: [defaultSchoolTermName],
    },
  };
};

export const requestSaveAlbum = (state: AlbumsState) => {
  return {
    ...state,
    formMeta: {
      ...state.formMeta,
      isSubmittingAlbum: true,
    },
  };
};

export const requestSaveAlbumSuccess = (state: AlbumsState) => ({
  ...state,
  formMeta: {
    ...state.formMeta,
    isSubmittingAlbum: false,
  },
});

export const fetchAlbumSuccess = (
  state: AlbumsState,
  {
    album,
    albumIncluded,
  }: {
    album: AlbumFormResponse['data'];
    albumIncluded: AlbumStudentProfileIncluded[];
  }
) => {
  const classroom_with_student_profile_ids =
    album.attributes?.classroomWithStudentProfileIds?.reduce(
      (prev, current) => {
        const [[key, ids]] = Object.entries(current);

        return { ...prev, [key]: ids.map(String) };
      },
      {}
    );

  const student_profiles = albumIncluded.map((profile) => ({
    ...profile,
    attributes: {
      ...profile.attributes,
      name_initials: profile.attributes.nameInitials,
      avatar_color: profile.attributes.avatarColor,
      avatar_url: profile.attributes.avatarUrl,
    },
  }));

  return {
    ...state,
    current: {
      ...state.current,
      sent_kind:
        album.attributes.category === 'classroom' ? 'classrooms' : 'students',
      title: album.attributes.name,
      description: album.attributes.description,
      classroom_ids: album.relationships.recipients.data.map(
        (classroom) => classroom.id
      ),
      classroom_with_student_profile_ids,
    },
    formMeta: {
      ...state.formMeta,
      hideRadio: true,
      student_profiles,
    },
  };
};

export const fetchAlbumDetails = (state: AlbumsState) => ({
  ...state,
  isLoading: true,
});

export const fetchAlbumDetailsSuccess = (
  state: AlbumsState,
  action: {
    album: AlbumDetailsResponse['data'];
    albumIncluded: AlbumDetailsResponse['included'];
  }
) => ({
  ...state,
  isLoading: false,
  albumDetails: action.album,
  albumDetailsIncluded: action.albumIncluded,
});

export const fetchAlbumDetailsMedias = (state: AlbumsState) => ({
  ...state,
  isLoadingAlbumDetailsMedias: true,
});

export const fetchAlbumDetailsMediasSuccess = (
  state: AlbumsState,
  action: {
    data: AlbumDetailsMediasResponse['data'];
    included: AlbumDetailsMediasResponse['included'];
    links: AlbumDetailsMediasResponse['links'];
    isPagination: boolean;
  }
) => ({
  ...state,
  isLoadingAlbumDetailsMedias: false,
  albumDetailsMedias: action.isPagination
    ? [...state.albumDetailsMedias, ...action.data]
    : action.data,
  albumDetailsMediasIncluded: action.isPagination
    ? [...state.albumDetailsMediasIncluded, ...action.included]
    : action.included,
  albumDetailsMediasPaginationLinks: action.links,
});

export const deleteMediaSuccess = (
  state: AlbumsState,
  action: {
    id: string;
  }
) => ({
  ...state,
  albumDetailsMedias: state.albumDetailsMedias.filter(
    (media) => media.id !== action.id
  ),
});

export const deleteAlbumSuccess = (
  state: AlbumsState,
  action: DeletedAlbumResponse
) => ({
  ...state,
  albums: state.albums.map((album) => {
    if (album.id === action.data.id) {
      const deletingAlbum = {
        ...album,
        attributes: {
          ...album.attributes,
          deleting: action.data.attributes.deleting,
          scheduledDeletionTime: action.data.attributes.scheduledDeletionTime,
        },
      };

      return deletingAlbum;
    }

    return album;
  }),
  albumDetails: state.albumDetails
    ? {
        ...state.albumDetails,
        attributes: {
          ...state.albumDetails.attributes,
          updatedAt: action.destroyEvent.attributes.createdAt,
          scheduledDeletionTime: action.data.attributes.scheduledDeletionTime,
          deleting: true,
        },
        relationships: {
          ...state.albumDetails.relationships,
          versions: {
            data: [
              ...state.albumDetails.relationships.versions.data,
              action.destroyEvent,
            ],
          },
        },
      }
    : null,
  albumDetailsIncluded: [...state.albumDetailsIncluded, action.destroyEvent],
});

export const recoverAlbumSuccess = (
  state: AlbumsState,
  action: { id: string; restoreEvent: AlbumDetailsResponse['included'] }
) => ({
  ...state,
  albums: state.albums.map((album) => {
    if (album.id === action.id) {
      return {
        ...album,
        attributes: {
          ...album.attributes,
          deleting: false,
        },
      };
    }

    return album;
  }),
  albumDetails: state.albumDetails
    ? {
        ...state.albumDetails,
        attributes: {
          ...state.albumDetails.attributes,
          deleting: false,
        },
        relationships: {
          ...state.albumDetails.relationships,
          versions: {
            data: [
              ...state.albumDetails.relationships.versions.data,
              action.restoreEvent,
            ],
          },
        },
      }
    : null,
  albumDetailsIncluded: [...state.albumDetailsIncluded, action.restoreEvent],
});

export const approvePendingsSuccess = (
  state: AlbumsState,
  action: {
    id: string;
  }
) => ({
  ...state,
  albumDetails: {
    ...state.albumDetails,
    attributes: {
      ...state.albumDetails.attributes,
      approving: true,
    },
  },
});

export const setMediaCoverSuccess = (
  state: AlbumsState,
  action: {
    id: string;
  }
) => ({
  ...state,
  albumDetailsMedias: state.albumDetailsMedias.map((currentMedia) => {
    if (currentMedia.attributes.isCover) {
      const removedCover = {
        ...currentMedia,
        attributes: { ...currentMedia.attributes, isCover: false },
      };

      return removedCover;
    }

    if (currentMedia.id === action.id) {
      const mediaCover = {
        ...currentMedia,
        attributes: { ...currentMedia.attributes, isCover: true },
      };

      return mediaCover;
    }

    return currentMedia;
  }),
});

export const addMediaRequest = (state: AlbumsState) => ({
  ...state,
  isUploadingMedias: true,
});

export const addMediaSuccess = (state: AlbumsState) => ({
  ...state,
  isUploadingMedias: false,
});

export const addMediaError = (state: AlbumsState) => ({
  ...state,
  isUploadingMedias: false,
});

export const updateMediaRequest = (state: AlbumsState) => ({
  ...state,
  isUpdatingAlbumMedia: true,
});

export const updateMediaSuccess = (
  state: AlbumsState,
  action: {
    id: string;
    media: AlbumMedia;
  }
) => ({
  ...state,
  isUpdatingAlbumMedia: false,
  albumDetailsMedias: state.albumDetailsMedias.map((currentMedia) =>
    currentMedia.id === action.id ? action.media : currentMedia
  ),
});

export const updateMediaError = (state: AlbumsState) => ({
  ...state,
  isUpdatingAlbumMedia: false,
});

export const fetchPhotoVisibilityStudentsByHeadquarter = (
  state: AlbumsState
) => ({
  ...state,
  isLoadingPhotoVisibilityStudentsByHeadquarter: true,
});

export const fetchPhotoVisibilityStudentsByHeadquarterSuccess = (
  state: AlbumsState,
  action: PhotoVisibilityStudentsByHeadquarterResponse
) => ({
  ...state,
  isLoadingPhotoVisibilityStudentsByHeadquarter: false,
  photoVisibilityStudentsByHeadquarter: action.data,
  photoVisibilityStudentsByHeadquarterMeta: action.meta,
});

export const fetchPhotoVisibilityStudentsByHeadquarterError = (
  state: AlbumsState
) => ({
  ...state,
  isLoadingPhotoVisibilityStudentsByHeadquarter: false,
});

export const fetchPhotoVisibilityStudents = (state: AlbumsState) => ({
  ...state,
  isLoadingPhotoVisibilityStudents: true,
});

export const fetchPhotoVisibilityStudentsSuccess = (
  state: AlbumsState,
  action: PhotoVisibilityStudentsResponse
) => ({
  ...state,
  isLoadingPhotoVisibilityStudents: false,
  photoVisibilityStudents: action.data,
});

export const fetchPhotoVisibilityStudentsError = (state: AlbumsState) => ({
  ...state,
  isLoadingPhotoVisibilityStudents: false,
});

const HANDLERS = {
  [actions.FETCH_ALBUMS]: fetchAlbums,
  [actions.FETCH_ALBUMS_SUCCESS]: fetchAlbumsSuccess,
  [actions.SET_FILTER]: setFilter,
  [filterActions.FETCH_ACTIVE_SCHOOL_TERMS_SUCCESS]: setDefaultSchoolTerm,
  [actions.FETCH_FORM_CLASSROOMS_SUCCESS]: fetchFormClassroomsSuccess,
  [actions.REQUEST_SAVE_ALBUM]: requestSaveAlbum,
  [actions.REQUEST_SAVE_ALBUM_SUCCESS]: requestSaveAlbumSuccess,
  [actions.FETCH_ALBUM_SUCCESS]: fetchAlbumSuccess,
  [actions.FETCH_ALBUM_DETAILS]: fetchAlbumDetails,
  [actions.FETCH_ALBUM_DETAILS_SUCCESS]: fetchAlbumDetailsSuccess,
  [actions.FETCH_ALBUM_DETAILS_MEDIAS]: fetchAlbumDetailsMedias,
  [actions.FETCH_ALBUM_DETAILS_MEDIAS_SUCCESS]: fetchAlbumDetailsMediasSuccess,
  [actions.DELETE_MEDIA_SUCCESS]: deleteMediaSuccess,
  [actions.DELETE_ALBUM_SUCCESS]: deleteAlbumSuccess,
  [actions.RECOVER_ALBUM_SUCCESS]: recoverAlbumSuccess,
  [actions.APPROVE_PENDINGS_SUCCESS]: approvePendingsSuccess,
  [actions.SET_MEDIA_COVER_SUCCESS]: setMediaCoverSuccess,
  [actions.ADD_MEDIA]: addMediaRequest,
  [actions.ADD_MEDIA_SUCCESS]: addMediaSuccess,
  [actions.ADD_MEDIA_ERROR]: addMediaError,
  [actions.UPDATE_MEDIA]: updateMediaRequest,
  [actions.UPDATE_MEDIA_SUCCESS]: updateMediaSuccess,
  [actions.UPDATE_MEDIA_ERROR]: updateMediaError,
  [actions.FETCH_PHOTO_VISIBILITY_STUDENTS_BY_HEADQUARTER]:
    fetchPhotoVisibilityStudentsByHeadquarter,
  [actions.FETCH_PHOTO_VISIBILITY_STUDENTS_BY_HEADQUARTER_SUCCESS]:
    fetchPhotoVisibilityStudentsByHeadquarterSuccess,
  [actions.FETCH_PHOTO_VISIBILITY_STUDENTS_BY_HEADQUARTER_ERROR]:
    fetchPhotoVisibilityStudentsByHeadquarterError,
  [actions.FETCH_PHOTO_VISIBILITY_STUDENTS]: fetchPhotoVisibilityStudents,
  [actions.FETCH_PHOTO_VISIBILITY_STUDENTS_SUCCESS]:
    fetchPhotoVisibilityStudentsSuccess,
  [actions.FETCH_PHOTO_VISIBILITY_STUDENTS_ERROR]:
    fetchPhotoVisibilityStudentsError,

  [actions.ERROR]: handleError,
};

const albums = createModuleReducer(INITIAL_STATE, HANDLERS);

export default albums;
