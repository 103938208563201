import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import FormContainer from 'components/Form/FormContainer';
import Toast from 'components/Toast';
import Field from 'components/Form/Field';
import { TextField, Box, Button } from '@agendaedu/ae-web-components';

import ButtonAndSideSheet from 'pages/dailySummaries/ButtonAndSideSheet';
import List from 'pages/dailySummaries/List';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import DailySummariesActions from 'store/dailySummaries/actions';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

const DailySummaries = ({ formContext: { form } }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('diary');

  const { fetchClassroomsList, setDate } = DailySummariesActions;

  const {
    formMeta: { filterAmount },
    canManageSections,
  } = useSelector((state) => state.dailySummaries);

  const handleFilters = (params) => {
    dispatch(fetchClassroomsList(params));
  };

  const handleDateChange = (newDate) => {
    const momentDate = moment(newDate);
    dispatch(setDate(momentDate));
    handleFilters({ date: newDate ? momentDate : null, page: 1 });
  };

  const getOnlyPastDates = (day) => {
    return day.isAfter(moment().endOf('day'));
  };

  const showOnboardingIDs = () =>
    canManageSections ? 'DailySummariesListScreen' : '';

  return (
    <S.DailySummariesContainer id={showOnboardingIDs()}>
      <S.DailySummariesTitle>{t('list.title')}</S.DailySummariesTitle>

      <FormContainer variation="horizontal">
        <S.ActionsBtnWrapper>
          <S.SectionWrapper>
            <TextField
              className="search-filter"
              icon="search"
              placeholder="Buscar turma"
              onChange={({ target }) => handleFilters({ name: target.value })}
              onKeyPress={(event) => {
                if (event.key === 'Enter')
                  handleFilters({ name: event.target.value });
              }}
            />

            <Field
              placeholder="Data inicial"
              type="date"
              mobileSize="extraLarge"
              vertical
              defaultValue={moment()}
              onValueChange={handleDateChange}
              isOutsideRange={getOnlyPastDates}
              withActionButtons
              showClearDates={false}
            />
          </S.SectionWrapper>

          <S.BtnActionWrapper>
            <Box mr="xs">
              <Link
                to="/schools/daily_summaries/report"
                data-testid="export-report-button"
              >
                <Button
                  variant="secondary"
                  aria-label={t('list.export_report')}
                >
                  {t('list.export_report')}
                </Button>
              </Link>
            </Box>

            <S.BtnWrapper>
              {canManageSections && (
                <Link
                  to="/schools/diary_sections"
                  data-testid="section-settings-button"
                >
                  <Button
                    variant="secondary"
                    aria-label="Configurações"
                    isOnlyIcon
                    icon="cog"
                  />
                </Link>
              )}

              <ButtonAndSideSheet
                amount={filterAmount}
                handleFilter={handleFilters}
              />
            </S.BtnWrapper>
          </S.BtnActionWrapper>
        </S.ActionsBtnWrapper>
      </FormContainer>

      <List />

      <Toast />
    </S.DailySummariesContainer>
  );
};

DailySummaries.propTypes = {
  ...formPropTypes,
};

export default withFormContext(DailySummaries);
