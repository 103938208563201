import { SetCreateNewChatParams } from 'store/messages/omniChannel/types';
import { Recipient } from './types';

export const normalizeCreateNewMessage = (params: SetCreateNewChatParams) => {
  const { fileSignedId, message, recipients } = params;

  const formatedRecipients: Recipient[] = recipients.map((recipient) => {
    if (recipient.tab === 'student') {
      return {
        kind: 'private',
        student_profile_id: recipient.student.id,
        user: {
          id: recipient.student.id,
          type: 'student_profile',
        },
      };
    }

    if (recipient.tab === 'family') {
      return {
        kind: 'family',
        student_profile_id: recipient.student.id,
        user: {
          id: recipient.student.id,
          type: 'student_profile',
        },
      };
    }

    if (recipient.tab === 'responsible') {
      return {
        kind: 'private',
        student_profile_id: recipient.student.id,
        user: {
          id: recipient.responsible.id,
          type: 'responsible_profile',
        },
      };
    }
  });

  const data = new FormData();

  data.append('resource', 'chat');
  data.append('message[message]', message.trim() || '');
  data.append('recipients', JSON.stringify(formatedRecipients));
  data.append('message[direct_upload_attachment]', fileSignedId);

  return { data };
};
