import React, { useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import AlbumActions from 'store/albums/actions';

import {
  Box,
  Button,
  Modal,
  Text,
  TextField,
} from '@agendaedu/ae-web-components';
import { Props } from './types';
import { useDispatch } from 'react-redux';

export const DeleteAlbumModal = ({
  album,
  isOpen,
  onClose,
}: Props): JSX.Element => {
  const { t } = useTranslation(['albums', 'common']);
  const [confirmSubmitText, setConfirmSubmitText] = useState('');

  const isDisabled =
    confirmSubmitText !==
    t('list.album_card.delete_album_modal.input_placeholder');

  const dispatch = useDispatch();

  const { deleteAlbum } = AlbumActions;

  const onConfirmeDelete = () => {
    dispatch(deleteAlbum(album.id));
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('list.album_card.delete_album_modal.title')}
      onClose={onClose}
    >
      <Box width="100%">
        <Text
          data-testid="text-content-modal"
          variant="body-regular-16"
          mx="xs3"
          maxWidth={360}
          lineHeight="lg"
        >
          <Trans>
            {t('list.album_card.delete_album_modal.content', {
              albumName: album.attributes.name,
            })}
          </Trans>
        </Text>

        <Box display="flex" mt="lg" mx="xs3">
          <TextField
            id="input-delete-album-confirmation"
            label={t('list.album_card.delete_album_modal.input_label')}
            fullWidth
            placeholder={t(
              'list.album_card.delete_album_modal.input_placeholder'
            )}
            value={confirmSubmitText}
            onChange={(event) =>
              setConfirmSubmitText(event.currentTarget.value)
            }
          />
        </Box>

        <Box display="flex" mt="lg" justifyContent="flex-end">
          <Button
            id="cancel-album-delete-button"
            testId="back-button"
            variant="secondary"
            mr="sm"
            onClick={onClose}
          >
            {t('common:button.back')}
          </Button>
          <Button
            id="confirm-album-delete-button"
            testId="delete-button"
            contextVariant="alert"
            disabled={isDisabled}
            variant="secondary"
            onClick={onConfirmeDelete}
          >
            {t('common:button.delete')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
