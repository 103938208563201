import React from 'react';

export type TicketStatus = 'waiting' | 'in_attendance' | 'done';
export type TicketTagVariant = 'positive' | 'warning' | 'negative';

export const IMAGES_PAYMENT_URL = {
  availabilityUrl:
    'https://static.agendaedu.com/payments/empty_state_wallet.png',
  emptyStateUrl: 'https://static.agendaedu.com/payments/emptyState.png',
  onboardingUrl: 'https://static.agendaedu.com/payments/onboarding.png',
  recurrentProductUrl:
    'https://static.agendaedu.com/payments/empty_state_products.png',
  recurrentPlanUrl: 'https://static.agendaedu.com/payments/recurrent.png',
  recurrentPlanDisabledUrl:
    'https://static.agendaedu.com/payments/recurrent_plan_disabled.png',
  schoolProductUrl: 'https://static.agendaedu.com/payments/school_product.png',
  enrollmentUrl: 'https://static.agendaedu.com/payments/enrollment.png',
  enrollmentEmptyStateFilterUrl:
    'https://static.agendaedu.com/survey/app_error.png',
  offerUrl: 'https://static.agendaedu.com/shop/EduPay_Shop_bag.png',
  emptyWalletHeader:
    'https://static.agendaedu.com/payments/onboarding/header.png',
  schoolProductCard:
    'https://static.agendaedu.com/payments/onboarding/school_product.png',
  recurrenceCard:
    'https://static.agendaedu.com/payments/onboarding/recurrence.png',
  enrollmentCard:
    'https://static.agendaedu.com/payments/onboarding/enrollment.png',
  shopCard: 'https://static.agendaedu.com/payments/onboarding/shop.png',
  modal: 'https://static.agendaedu.com/payments/onboarding/modal.png',
};

export const IMAGES_JOURNAL_DOMAIN_URL = {
  selectStudentInfo:
    'https://static.agendaedu.com/journal_domain/select_student_information.png',
};

export const IMAGES_SURVERY_URL = {
  allResponsibles: 'https://static.agendaedu.com/icons/RFs64px.png',
  responsibles: 'https://static.agendaedu.com/survey/responsibles.png',
  students: 'https://static.agendaedu.com/survey/students.png',
  emptyStateUrl: 'https://static.agendaedu.com/survey/app_error.png',
  authorizationOn: 'https://static.agendaedu.com/survey/autorizacao-on.png',
  authorizationOff: 'https://static.agendaedu.com/survey/autorizacao-off.png',
  pollOn: 'https://static.agendaedu.com/survey/enquete-on.png',
  pollOff: 'https://static.agendaedu.com/survey/enquete-off.png',
};

export const SURVEYS_STATUS = [
  { label: 'Todas situações', value: '' },
  { label: 'Pendente', value: 'pending' },
  { label: 'Em aberto', value: 'opened' },
  { label: 'Encerrado', value: 'closed' },
  { label: 'Reprovada', value: 'rejected' },
];

export const SURVEY_LOG_STATUS = [
  { label: 'Todas situações', value: '' },
  { label: 'Pendente', value: 'pending' },
  { label: 'Visualizada', value: 'seen' },
  { label: 'Respondida', value: 'answered' },
];

export const IMAGES_INVITE_URL = {
  inviteBoxThumbnail:
    'https://static.agendaedu.com/invite/thumbnail/empty_state_invite_link.png',
  inviteResponsiblesThumbnail:
    'https://static.agendaedu.com/empty_state/modal-thumb-responsibles.png',
  inviteStudentsThumbnail:
    'https://static.agendaedu.com/empty_state/modal-thumb-students.png',
};

export const IMAGES_HELP_URL = {
  onboarding:
    'https://agenda-public.s3.us-west-2.amazonaws.com/fotos/help-onboarding.png',
};

export const EMPTY_STATES_URL = {
  classroomsURl: 'https://static.agendaedu.com/empty_state/classrooms.png',
  disciplinesUrl: 'https://static.agendaedu.com/empty_state/disciplines.png',
  eventsUrl: 'https://static.agendaedu.com/empty_state/events.png',
  responsiblesUrl:
    'https://static.agendaedu.com/empty_state/empty-state-responsibles.png',
  studentsUrl:
    'https://static.agendaedu.com/empty_state/empty-state-students.png',
  teachersUrl: 'https://static.agendaedu.com/empty_state/teachers.png',
  teamUrl: 'https://static.agendaedu.com/empty_state/team.png',
  headquartersUrl: 'https://static.agendaedu.com/empty_state/headquarters.png',
  negotiationsUrl: 'https://static.agendaedu.com/empty_state/negotiation.png',
  handoutCategoriesUrl: 'https://static.agendaedu.com/handout/professora-2.svg',
  photoPlaceholder: 'https://static.agendaedu.com/public/placeholder.png',
  defaultCover: 'https://static.agendaedu.com/album/default_cover.png',
};

export const SCHOOL_PRODUCTS_FILTER_TYPES = [
  { label: 'Aluno', value: 'student_name' },
  { label: 'Título', value: 'payment_title' },
];

export const SEARCH_TYPE = [
  { label: 'Aluno', value: 'student' },
  { label: 'Título', value: 'title' },
];

export const ORDER_STATUSES = [
  { label: 'Todas as situações', value: null },
  { label: 'A reembolsar', value: 'to_refund' },
  { label: 'Cancelado', value: 'canceled' },
  { label: 'Gerou boleto', value: 'generated_billet' },
  { label: 'Gerou PIX', value: 'generated_pix' },
  { label: 'Pago', value: 'paid' },
  { label: 'Em aberto', value: 'waiting_payment' },
  { label: 'Processando', value: 'processing' },
  { label: 'Reembolsado', value: 'refunded' },
  { label: 'Valor divergente', value: 'paid_divergent' },
  { label: 'Encerrado', value: 'expired' },
];

export const ORDER_STATUS_TAGS = {
  generated_billet: 'informative',
  billet_paid: 'positive',
  credit_card_paid: 'positive',
  waiting_payment: 'warning',
  processing: 'informative',
  authorized: 'informative',
  paid: 'positive',
  expired: 'neutral',
  canceled: 'neutral',
  processing_refund: 'informative',
  refunded: 'neutral',
  to_refund: 'informative',
  refused: 'negative',
  manual_processing: 'warning',
  rejected_by_responsible: 'negative',
  manually_paid: 'positive',
  paid_divergent: 'warning',
  generated_pix: 'informative',
  pix_paid: 'positive',
  negotiation: 'warning',
  negotiated: 'warning',
  delayed: 'negative',
};

export const SCHOOL_PRODUCTS_STATUSES = [
  { label: 'Cobranças ativas', value: 'active' },
  { label: 'Todas as situações', value: null },
  { label: 'Cancelados', value: 'canceled' },
  { label: 'Deletados', value: 'deleted' },
  { label: 'Encerrados', value: 'expired' },
  { label: 'Enviados', value: 'sent' },
  { label: 'Não aprovados', value: 'rejected' },
  { label: 'Pendente de aprovação', value: 'pending' },
];

export const RECURRENT_BILL_STATUSES = [
  { label: 'Cobranças ativas', value: null },
  { label: 'Atrasado', value: 'delayed' },
  { label: 'Cancelado', value: 'canceled' },
  { label: 'Em aberto', value: 'released' },
  { label: 'Gerou boleto', value: 'generated_billet' },
  { label: 'Gerou PIX', value: 'generated_pix' },
  { label: 'Não enviado', value: 'pending' },
  { label: 'Negociado', value: 'negotiated' },
  { label: 'Pago', value: 'paid' },
  { label: 'Pago atrasado', value: 'paid_late' },
  { label: 'Pago na escola', value: 'manually_paid' },
  { label: 'Pago via boleto', value: 'billet_paid' },
  { label: 'Pago via PIX', value: 'pix_paid' },
  { label: 'Pago via cartão', value: 'credit_card_paid' },
  { label: 'Recusado', value: 'refused' },
  { label: 'Todas as situações', value: 'all_situations' },
  { label: 'Valor divergente', value: 'paid_divergent' },
];

export const CHECKOUT_PAYMENT_METHODS_TOOLTIP_MESSAGES = {
  options:
    'Por padrão, a cobrança já vêm com todos os<br/>métodos de pagamento pré-selecionados.',
  info: 'Parcelamento disponível para todas as<br/>cobranças pagas pelo responsável. Taxa única<br/>para a escola, independente do número de<br/>parcelas.',
  info_absorb_tax:
    'Escolhendo este método, mas sem habilitar o<br/>parcelamento sem taxa, a escola paga um valor<br/>único independentemente do número de parcelas.',
};

export const ENROLLMENT_DOCUMENT_STATUSES = [
  { label: 'Todas as situações', value: null },
  { label: 'Cancelado', value: 'canceled' },
  { label: 'Finalizado', value: 'finished' },
  { label: 'Pendente', value: 'pending' },
];

export const ENROLLMENT_STATUSES = [
  { label: 'Todas as situações', value: null },
  { label: 'Cancelado', value: 'canceled' },
  { label: 'Enviado', value: 'sent' },
  { label: 'Gerou boleto', value: 'generated_billet' },
  { label: 'Gerou PIX', value: 'generated_pix' },
  { label: 'Pago na Escola', value: 'manually_paid' },
  { label: 'Pago via boleto', value: 'billet' },
  { label: 'Pago via cartão', value: 'credit_card' },
  { label: 'Pago via PIX', value: 'pix' },
  { label: 'Pendente', value: 'pending' },
  { label: 'Recusado', value: 'refused' },
];

export const PAYMENT_METHODS_BY_SCHOOL_PRODUCT = [
  { label: 'Todos os métodos', value: null },
  { label: 'Boleto', value: 'billet' },
  { label: 'Cartão de Crédito', value: 'credit_card' },
  { label: 'Pago na Escola', value: 'manually_paid' },
  { label: 'PIX', value: 'pix' },
];

export const PAYMENT_METHODS = [
  { label: 'Todos os métodos', value: null },
  { label: 'Boleto', value: 'billet' },
  { label: 'Cartão de Crédito', value: 'credit_card' },
  { label: 'PIX', value: 'pix' },
  { label: 'Pago na Escola', value: 'manually_paid' },
];

export const SCHOOL_WALLET_PRODUCT_STATUSES = [
  { label: 'Todas as situações', value: '' },
  { label: 'Em aberto', value: 'sent' },
  { label: 'Encerrado', value: 'expired' },
  { label: 'Cancelado', value: 'canceled' },
];

export const SCHOOL_WALLET_TRANSFER_STATUSES = [
  { label: 'Todas as situações', value: '' },
  { label: 'Processando', value: 'processing' },
  { label: 'Transferido', value: 'transferred' },
  { label: 'Erro', value: 'failed' },
];

export const EMPTY_USER = {
  data: {
    attributes: {
      name: 'Usuário excluído',
      name_initials: 'UE',
    },
  },
};

export const DISPLAY_TRANSACTION_ID_TO_STATUS = [
  'credit_card_paid',
  'billet_paid',
  'pix_paid',
  'generated_billet',
  'generated_pix',
];

export const CAN_ENROLLMENT_CANCEL_BY_STATUS = [
  'processing',
  'pending',
  'generated_billet',
  'generated_pix',
  'refused',
  'waiting_payment',
  'sent',
  'paid',
  'billet_paid',
  'credit_card_paid',
  'manually_paid',
  'pix_paid',
  'paid_late',
];

export const DISPLAY_STATUS_PAID = [
  'billet_paid',
  'credit_card_paid',
  'manually_paid',
  'paid_divergent',
  'pix_paid',
  'paid_late',
];

export const CAN_REVERT_MANUAL_PAYMENT_BY_RECURRENT_STATUS = [
  'manually_paid',
  'paid_divergent',
];

export const CAN_GENERATE_BILLET_OR_PIX_BY_SCHOOL_PRODUCT_STATUS = [
  'waiting_payment',
  'generated_billet',
  'generated_pix',
  'refused',
];

export const CAN_GENERATE_BILLET_OR_PIX_BY_RECURRENT_STATUS = [
  'released',
  'delayed',
  'refused',
  'generated_billet',
  'generated_pix',
];

export const CAN_MANUALLY_PAID_BY_RECURRENT_STATUS = [
  'pending',
  'released',
  'generated_billet',
  'generated_pix',
  'delayed',
  'refused',
];

export const CAN_DELAY_RECURRENT_BILL_BY_STATUS = [
  'released',
  'generated_billet',
  'generated_pix',
];

export const ALLOWED_PAYMENT_METHODS = {
  billet: 'Boleto',
  credit_card: 'Cartão de crédito',
  pix: 'PIX',
};

export const DISCOUNT_KINDS = [
  {
    name: '%',
    value: 'percent',
  },
  {
    name: 'R$',
    value: 'money',
  },
];

export const INSTALLMENTS_SCHOOLPRODUCT = [
  {
    label: '1 vez',
    value: 1,
  },
  {
    label: '2 vezes',
    value: 2,
  },
  {
    label: '3 vezes',
    value: 3,
  },
  {
    label: '4 vezes',
    value: 4,
  },
  {
    label: '5 vezes',
    value: 5,
  },
  {
    label: '6 vezes',
    value: 6,
  },
  {
    label: '7 vezes',
    value: 7,
  },
  {
    label: '8 vezes',
    value: 8,
  },
  {
    label: '9 vezes',
    value: 9,
  },
  {
    label: '10 vezes',
    value: 10,
  },
  {
    label: '11 vezes',
    value: 11,
  },
  {
    label: '12 vezes',
    value: 12,
  },
];

export const IMAGES_CLASSROOMS_URL = {
  responsibles: 'https://static.agendaedu.com/survey/responsibles.png',
  students: 'https://static.agendaedu.com/survey/students.png',
  emptyStateUrl: 'https://static.agendaedu.com/survey/app_error.png',
  emptyComments: 'https://static.agendaedu.com/learning/no-comments.png',
  commentsDisabled:
    'https://static.agendaedu.com/learning/comments-disabled.png',
};

export const CLASSROOMS_STATUS = [
  { label: 'Todas as situações', value: '' },
  { label: 'Enviadas', value: 'sent' },
  { label: 'Agendadas', value: 'scheduled' },
  { label: 'Não aprovadas', value: 'rejected' },
  { label: 'Pendente', value: 'pending' },
  { label: 'Excluídas', value: 'only_deleted' },
];

export const DATE_MONTHS = [
  {
    name: 'Jan',
    value: 0,
  },
  {
    name: 'Fev',
    value: 1,
  },
  {
    name: 'Mar',
    value: 2,
  },
  {
    name: 'Abr',
    value: 3,
  },
  {
    name: 'Mai',
    value: 4,
  },
  {
    name: 'Jun',
    value: 5,
  },
  {
    name: 'Jul',
    value: 6,
  },
  {
    name: 'Ago',
    value: 7,
  },
  {
    name: 'Set',
    value: 8,
  },
  {
    name: 'Out',
    value: 9,
  },
  {
    name: 'Nov',
    value: 10,
  },
  {
    name: 'Dez',
    value: 11,
  },
];

export const walletStatus = {
  registration: {
    text: 'Aprovação pendente',
  },
  refused: {
    text: 'Recusada',
  },
  blocked: {
    text: 'Bloqueada',
  },
  inactive: {
    text: 'Inativa',
  },
  active: {
    text: 'Ativa',
  },
};

export const optionsFilerByType = [
  {
    value: null,
    label: 'Todas as Cobranças',
  },
  {
    value: 'single_payment',
    label: 'Cobranças - Únicas',
  },
  {
    value: 'shop',
    label: 'Cobranças - Shop',
  },
];

export const optionsFilterByOrigin = [
  {
    value: null,
    label: 'Todas as Cobranças',
  },
  {
    value: 'platform',
    label: 'Cobranças - Plataforma',
  },
  {
    value: 'api_v2',
    label: 'Cobranças - API',
  },
];

export const OFFER_STATUS = {
  not_started: { variation: 'info', label: 'Não Iniciada' },
  active: { variation: 'success', label: 'Disponível' },
  canceled: { variation: 'default', label: 'Cancelada' },
  closed: { variation: 'default', label: 'Encerrada' },
  stock_exceeded: { variation: 'danger', label: 'Sem Estoque' },
};

export const PAYMENT_METHODS_ICONS = {
  credit_card: { iconName: 'credit-card', label: 'Cartão' },
  pix: { iconName: 'pix', label: 'Pix' },
  billet: { iconName: 'billet', label: 'Boleto' },
};

export const TITLES_PAYMENT_METHODS = {
  creditCard: 'Cartão',
  billet: 'Boleto',
  pix: 'PIX',
};

export const NEW_OFFER_MAX_INSTALLMENTS = [
  { value: 1, label: '1 parcelas' },
  { value: 2, label: '2 parcelas' },
  { value: 3, label: '3 parcelas' },
  { value: 4, label: '4 parcelas' },
  { value: 5, label: '5 parcelas' },
  { value: 6, label: '6 parcelas' },
  { value: 7, label: '7 parcelas' },
  { value: 8, label: '8 parcelas' },
  { value: 9, label: '9 parcelas' },
  { value: 10, label: '10 parcelas' },
  { value: 11, label: '11 parcelas' },
  { value: 12, label: '12 parcelas' },
];

export const FINANCIAL_RESPONSIBLE_OPTIONS = [
  {
    label: (
      <span>
        <b>Todos os responsáveis cadastrados</b> receberão a cobrança e poderão
        efetuar o pagamento.
      </span>
    ),
    value: false,
    icon: IMAGES_SURVERY_URL.allResponsibles,
  },
  {
    label: (
      <span>
        <b>Apenas Responsáveis Financeiros</b> receberão a cobrança e poderão
        pagá-la. Os demais responsáveis <b>não</b> receberão a cobrança e{' '}
        <b>não</b> poderão pagá-la.
      </span>
    ),
    value: true,
    icon: IMAGES_SURVERY_URL.responsibles,
  },
];

export const ERROR_REDIRECT_MESSAGE =
  'O canal de destino não permite a criação de chats família';

export const ALLOWED_PAYMENT_METHOD = ['billet', 'pix'];
export const CAN_NEGOTIATE_RECURRENT_BILL_BY_STATUS = [
  'released',
  'delayed',
  'generated_pix',
  'generated_billet',
];

export const DAYS_BEFORE_EXPIRATION = [
  { value: 3, label: '3 dias antes do vencimento' },
  { value: 4, label: '4 dias antes do vencimento' },
  { value: 5, label: '5 dias antes do vencimento' },
  { value: 6, label: '6 dias antes do vencimento' },
  { value: 7, label: '7 dias antes do vencimento' },
  { value: 14, label: '14 dias antes do vencimento' },
  { value: 21, label: '21 dias antes do vencimento' },
  { value: 30, label: '30 dias antes do vencimento' },
];

export const DISCOUNT_DAYS_BEFORE_EXPIRATION = [
  { value: null, label: 'Selecione' },
  { value: 0, label: 'No dia' },
  { value: 1, label: '1 dia antes' },
  { value: 2, label: '2 dias antes' },
  { value: 3, label: '3 dias antes' },
  { value: 4, label: '4 dias antes' },
  { value: 5, label: '5 dias antes' },
  { value: 6, label: '6 dias antes' },
  { value: 7, label: '7 dias antes' },
  { value: 8, label: '8 dias antes' },
  { value: 9, label: '9 dias antes' },
  { value: 10, label: '10 dias antes' },
  { value: 11, label: '11 dias antes' },
  { value: 12, label: '12 dias antes' },
  { value: 13, label: '13 dias antes' },
  { value: 14, label: '14 dias antes' },
  { value: 15, label: '15 dias antes' },
  { value: 16, label: '16 dias antes' },
  { value: 17, label: '17 dias antes' },
  { value: 18, label: '18 dias antes' },
  { value: 19, label: '19 dias antes' },
  { value: 20, label: '20 dias antes' },
  { value: 21, label: '21 dias antes' },
  { value: 22, label: '22 dias antes' },
  { value: 23, label: '23 dias antes' },
  { value: 24, label: '24 dias antes' },
  { value: 25, label: '25 dias antes' },
];

export const MESSAGE_GROUP_IMAGES = {
  no_groups: 'https://static.agendaedu.com/group/no-groups.png',
  message_info: 'https://static.agendaedu.com/group/message-info.png',
  no_messages: 'https://static.agendaedu.com/group/no-messages.png',
};

export const HELP_URLS = {
  home: 'https://atendimento.agendaedu.com/hc/pt-br',
  forgotPassword:
    'https://atendimento.agendaedu.com/hc/pt-br/articles/360020466954-Esqueci-a-senha-o-que-fazer-',
  accountConfirmation:
    'https://atendimento.agendaedu.com/hc/pt-br/articles/360025584794-Como-confirmar-minha-conta-',
  gettingStarted:
    'https://atendimento.agendaedu.com/hc/pt-br/articles/360020654753-Come%C3%A7ando-a-usar-a-Agenda-Edu',
  polyvalentDisciplines:
    'https://atendimento.agendaedu.com/hc/pt-br/articles/360020448334-O-que-significa-disciplina-polivalente-na-plataforma-',
};

export const NEGOTIATION_STATUS = {
  pending: { variant: 'warning', name: 'Pendente' },
  active: { variant: 'positive', name: 'Ativa' },
  finished: { variant: 'positive', name: 'Finalizada' },
  negotiated: { variant: 'neutral', name: 'Negociada' },
  refused: { variant: 'negative', name: 'Recusado' },
  released: { variant: 'informative', name: 'Em Aberto' },
  generated_billet: { variant: 'informative', name: 'Gerou boleto' },
  billet_paid: { variant: 'positive', name: 'Pago via boleto' },
  credit_card_paid: { variant: 'positive', name: 'Pago via cartão' },
  manually_paid: { variant: 'positive', name: 'Pago na escola' },
  to_refund: { variant: 'warning', name: 'A reembolsar' },
  refunded: { variant: 'positive', name: 'Reembolsado' },
  canceled: { variant: 'negative', name: 'Cancelado' },
  delayed: { variant: 'negative', name: 'Atrasado' },
  pix_paid: { variant: 'positive', name: 'Pago via PIX' },
  processing_refund: { variant: 'warning', name: 'Processando reembolso' },
  generated_pix: { variant: 'positive', name: 'Gerou PIX' },
  paid: { variant: 'positive', name: 'Pago' },
  paid_late: { variant: 'positive', name: 'Pago atrasado' },
  pending_school_approval: {
    variant: 'warning',
    name: 'Pendente de aprovação da escola',
  },
  pending_bordero_approval: {
    variant: 'warning',
    name: 'Pendente de aprovação do borderô',
  },
  paid_divergent: { variant: 'positive', name: 'Valor divergente' },
};

export const MOOD_TRACKER_ICONS = {
  anxious: 'https://static.agendaedu.com/acompanhamento/anxious_mood.png',
  excited: 'https://static.agendaedu.com/acompanhamento/awesome_mood.png',
  neutral: 'https://static.agendaedu.com/acompanhamento/neutral_mood.png',
  happy: 'https://static.agendaedu.com/acompanhamento/happy_mood.png',
  sad: 'https://static.agendaedu.com/acompanhamento/sad_mood.png',
};

export const IMAGES_HUB_STORE_URL = {
  bannerUrl: 'https://static.agendaedu.com/hub_store/home-banner.png',
};

export const MOOD_TRACKER_TITLE = {
  anxious: 'Ansioso',
  sad: 'Triste',
  neutral: 'Neutro',
  happy: 'Feliz',
  excited: 'Animado',
};

export const DEFAULT_MOODTRACK_VALUE = {
  anxious: 0,
  sad: 0,
  neutral: 0,
  happy: 0,
  excited: 0,
};

export const HANDOUTS_IMAGES = {
  no_handouts: 'https://static.agendaedu.com/handout/no_handouts.png',
};

export const HANDOUTS_STATUS = [
  { label: 'Situação', value: null },
  { label: 'Enviados', value: 'sent' },
  { label: 'Agendados', value: 'scheduled' },
  { label: 'Não aprovados', value: 'rejected' },
  { label: 'Pendentes', value: 'pending' },
  { label: 'Excluídos', value: 'deleted' },
  { label: 'Sem turmas', value: 'withoutClassrooms' },
];

export const ENROLLMENT_FORM_TYPES = [
  {
    label: 'Contrato em massa',
    value: 'mass',
  },
  {
    label: 'Contrato único',
    value: 'single',
  },
];

export const MOOD_TRACKERS_ANSWER_TYPE = [
  { label: 'mood_answer_filter_options.all', value: '' },
  { label: 'mood_answer_filter_options.with_mood', value: 'with_mood' },
  {
    label: 'mood_answer_filter_options.without_mood',
    value: 'without_mood',
  },
];

export const CHECKOUT_METHODS = {
  billet: 'Boleto',
  pix: 'PIX',
};

export const CONTRACT_MODEL_STATUS = {
  active: { variant: 'positive', label: 'Ativo' },
  canceled: { variant: 'neutral', label: 'Desativado' },
};

export const TICKET_TAG_VARIANT = [
  {
    name: 'Aguardando',
    variant: 'negative' as TicketTagVariant,
    status: 'waiting' as TicketStatus,
  },
  {
    name: 'Em atendimento',
    variant: 'warning' as TicketTagVariant,
    status: 'in_attendance' as TicketStatus,
  },
  {
    name: 'Concluido',
    variant: 'positive' as TicketTagVariant,
    status: 'done' as TicketStatus,
  },
];

export const MESSAGE_CHAT_TABS = [
  {
    title: 'Responder',
    key: 'pending_answer_count',
    tabName: 'pending_answer',
  },
  {
    title: 'Aprovar',
    key: 'pending_approval_count',
    tabName: 'pending_approval',
  },
  {
    title: 'Todas',
    key: 'all',
    tabName: 'all',
  },
];

export const MESSAGE_PRIVATE_AND_FAMILY_CHAT_TABS = [
  {
    title: 'Aluno',
    key: null,
    tabName: 'student_profiles',
  },
  {
    title: 'Responsável',
    key: null,
    tabName: 'responsible_profiles',
  },
  {
    title: 'Família',
    key: null,
    tabName: 'family_profiles',
  },
];

export const MESSAGE_SEARCH_CHANNEL_FAMILY_CHAT_TABS = [
  {
    title: 'Família',
    key: null,
    tabName: 'family_profiles',
  },
];

export const TICKET_SEARCH_CHAT_TABS = [
  {
    title: 'Aluno',
    key: null,
    tabName: 'student_profile',
  },
  {
    title: 'Responsável',
    key: null,
    tabName: 'responsible_profile',
  },
];

export const MESSAGE_SEARCH_CHAT_TABS = [
  {
    title: 'Aluno',
    key: null,
    tabName: 'student_profiles',
  },
  {
    title: 'Responsável',
    key: null,
    tabName: 'responsible_profiles',
  },
];

export const OMNI_CHANNEL_CHAT_TAB = {
  0: 'private',
  1: 'private',
  2: 'family',
};

export const TICKET_CHAT_TABS = [
  {
    title: 'Aguardando',
    key: 'waitingTickets',
    tabName: 'waiting' as TicketStatus,
  },
  {
    title: 'Atendimento',
    key: 'inAttendanceTickets',
    tabName: 'in_attendance' as TicketStatus,
  },
  {
    title: 'Concluido',
    key: 'done',
    tabName: 'done' as TicketStatus,
  },
];

export const MODAL_CENTERED = {
  top: '50%',
  left: '50%',
  right: '50%',
  transform: 'translate(-50%, -50%)',
};

export const messagesReadStatus = {
  answered: 'mark_as_unread',
  unanswered: 'mark_as_read',
};

export const INTEGRATION_MONITOR_URLS = {
  emptyState: 'https://static.agendaedu.com/survey/app_error.png',
};

export const UPLOAD_IMAGES_KIND = {
  local: 'https://static.agendaedu.com/upload-images/cloud-upload.png',
  googleDrive: 'https://static.agendaedu.com/upload-images/google-drive.png',
};

export const attendingHoursDays = {
  blockOnlyNotification: false,
  days: {
    '0': {
      active: false,
      hours: [],
    },
    '1': {
      active: true,

      hours: [],
    },
    '2': {
      active: true,

      hours: [],
    },
    '3': {
      active: true,

      hours: [],
    },
    '4': {
      active: true,

      hours: [],
    },
    '5': {
      active: true,

      hours: [],
    },
    '6': {
      active: false,

      hours: [],
    },
  },
};

export const attendingHoursDaysWeek = {
  '0': 'sunday',
  '1': 'monday',
  '2': 'tuesday',
  '3': 'wednesday',
  '4': 'thursday',
  '5': 'friday',
  '6': 'saturday',
};
