import React, {
  useState,
  useEffect,
  Suspense,
  useMemo,
  lazy,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import withFormContext from 'core/hoc/withFormContext';
import storeActions from 'store/editDynamicModules/actions';
import Toast from 'components/Toast';
import AgendaIcon from 'components/AgendaIcon';
import Button from 'components/Button';
import ButtonRow from 'components/ButtonRow';
import Field from 'components/Form/Field';
import FormContainer from 'components/Form/FormContainer';
import GroupSelector from 'components/Form/GroupSelector';

import * as S from './styles';

const EditDynamicModules = ({ match, history }) => {
  const [selectedModule, setSelectedModule] = useState({});
  const [selectClassroomIds, setSelectClassroomIds] = useState([]);

  const dispatch = useDispatch();

  const displayToast = useCallback(
    async (message, type) => {
      await dispatch(buildToast(message, type));
    },
    [dispatch, buildToast]
  );

  useEffect(() => {
    dispatch(fetchDynamicModules());
    dispatch(fetchClassroomsModules(1));
  }, []);

  const {
    fetchDynamicModules,
    fetchClassroomsModules,
    updateDynamicModules,
    buildToast,
  } = storeActions;

  const { dynamicModules, classroomsModules, isLoading } = useSelector(
    (state) => state.editDynamicModules
  );

  const onSubmit = () => {
    if (Object.keys(selectedModule).length <= 0) {
      displayToast('Você deve selecionar ao menos um módulo', 'error');
      return;
    }

    dispatch(updateDynamicModules(selectedModule.id, selectClassroomIds));
  };

  const renderEmptyState = (
    <div>
      <AgendaIcon name="user-group" size="large" />
      <div>
        {Object.keys(selectedModule).length > 0
          ? 'Selecione uma turma'
          : 'Selecione uma módulo primeiro'}
      </div>
    </div>
  );

  return (
    <S.Page
      variation="centered"
      isLoading={isLoading}
      id="dynamic-modules"
      title="Selecione quais são os módulos ativos para cada turma."
      subtitle="ATENÇÃO: Os usuários não terão acesso aos módulos não selecionados."
      footer={
        <div className="pull-right">
          <ButtonRow>
            <Button
              type="submit"
              variation="primary"
              className="save-button"
              onClick={() => onSubmit()}
            >
              Salvar
            </Button>
          </ButtonRow>
        </div>
      }
    >
      <FormContainer variation="vertical">
        <Field
          className="input-dynaminc-module"
          mobileSize="extraLarge"
          placeholder="Selecione o módulo"
          attributeName="dynamicmodule"
          pointerEvents="none"
          type="selectWithIcon"
          value={selectedModule?.id}
          options={dynamicModules}
          withArrowSelect
          onChange={(e) =>
            setSelectedModule({
              id: e.value,
              classroomIds: e.classroomIds,
            })
          }
        />

        <GroupSelector
          key={selectedModule?.id}
          options={
            Object.keys(selectedModule).length > 0 ? classroomsModules : []
          }
          emptyState={renderEmptyState}
          attributeName="dynamicmoduleSchool"
          selectedValues={selectedModule?.classroomIds?.map(String) || []}
          onChange={(data) => setSelectClassroomIds(data)}
        />
      </FormContainer>
      <Toast />
    </S.Page>
  );
};

EditDynamicModules.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
};

export default withFormContext(EditDynamicModules);
