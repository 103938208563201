import React, { useMemo, useState } from 'react';

import * as S from './styles';

import { Classrooms } from './Recipients/Classrooms';
import { LoadingSkeleton } from './Skeleton/Loading';
import Tabs from './Tabs';
import { Students } from './Recipients/Students';
import { Family } from './Recipients/Family';
import { Responsibles } from './Recipients/Responsibles';

import { Props } from './types';

export const RecipientSelectList: React.FC<Props> = ({
  tabs,
  isLoading,
  kind,
}) => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleOnChangeTab = (tab: number) => setCurrentTab(tab);

  const recipients = useMemo(() => {
    if (kind === 'family') {
      return {
        0: <Family />,
      };
    }
    return {
      0: <Classrooms />,
      1: <Students />,
      2: <Responsibles />,
      3: <Family />,
    };
  }, [kind]);
  return (
    <S.Wrapper>
      {isLoading ? (
        <LoadingSkeleton />
      ) : (
        <>
          <Tabs
            currentTab={currentTab}
            onChangeTab={handleOnChangeTab}
            tabs={tabs}
          />
          {recipients[currentTab]}
        </>
      )}
    </S.Wrapper>
  );
};
