import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, FullScreenModal, Stepper } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import withAppContext from 'core/hoc/withAppContext';
import useWindowDimensions from 'core/hooks/useWindowDimensions';
import { isMobile } from 'core/utils/viewport';
import { ChatContext } from 'core/contexts/OmniChannel/Chat';
import { OmniChannelState } from 'store/messages/omniChannel/types';
import OmniChannelActions from 'store/messages/omniChannel/actions';

import DiscardModal from 'components/Messages/OmniChannel/Forms/DiscardModal';
import Toast from 'components/Toast';
import RecipientsStep from './Steps/RecipientsStep';
import MessageStep from './Steps/MessageStep';

import { Props } from './types';

const NewMessageChat = ({ history, match }: Props): ReactElement => {
  const { t } = useTranslation(['messages']);
  const dispatch = useDispatch();

  const { channelId } = match.params;

  const { currentStep, setCurrentStep, isInfoStepValid } =
    useContext(ChatContext);

  const { screenWidth } = useWindowDimensions();

  const [isMobileScreen, setIsMobile] = useState(isMobile(screenWidth));

  const { toggleChatDiscardModal, fetchChannelFormRequest } =
    OmniChannelActions;

  const dataArea = useSelector(
    (state: { root: { dataArea: string } }) => state.root.dataArea
  );

  const {
    form: { showDiscardModal },
  } = useSelector((state: OmniChannelState) => state.omniChannel.chat);

  const tBase = useCallback(
    (key: string) => t(`omni_channel.chat.${key}`),
    [t]
  );

  const stepInfos = useMemo(
    () => [
      {
        step: 1,
        title: tBase('first_step_title'),
        isActive: currentStep === 1,
        isDisabled: false,
      },
      {
        step: 2,
        title: tBase('secondary_step_title'),
        isActive: currentStep === 2,
        isDisabled: !isInfoStepValid,
      },
    ],
    [currentStep, tBase]
  );

  const getHeaderContent = useMemo(
    () => (
      <Box
        display="flex"
        justifyContent="center"
        position="absolute"
        left={0}
        right={0}
        width="min-content"
        mx="auto"
        gap="sm"
      >
        {stepInfos.map((item, index) => (
          <Stepper
            key={index}
            isMobile={isMobileScreen}
            onClick={() => setCurrentStep(item.step)}
            {...item}
          />
        ))}
      </Box>
    ),
    [isMobileScreen, stepInfos]
  );

  const handleToggleDiscardModal = useCallback(() => {
    dispatch(toggleChatDiscardModal());
  }, [dispatch, toggleChatDiscardModal]);

  const handleDiscardModal = useCallback(() => {
    history.push(`/${dataArea}/messages`);
    dispatch(toggleChatDiscardModal());
  }, [dataArea, dispatch, history, toggleChatDiscardModal]);

  const getStepContent = {
    1: <RecipientsStep />,
    2: <MessageStep />,
  }[currentStep];

  useEffect(() => {
    if (isMobileScreen !== isMobile(screenWidth)) {
      setIsMobile(isMobile(screenWidth));
    }
  }, [isMobileScreen, screenWidth]);

  useEffect(() => {
    dispatch(fetchChannelFormRequest(channelId));
  }, [channelId, dispatch, fetchChannelFormRequest]);

  return (
    <div data-testid="new-message-chat">
      <FullScreenModal
        isOpen
        title={tBase('new_chat_button_text')}
        headerContent={getHeaderContent}
        onClose={handleToggleDiscardModal}
      >
        <Box
          data-testid="content"
          display="flex"
          flex={1}
          overflow="auto"
          mb={72}
        >
          {getStepContent}
        </Box>
      </FullScreenModal>

      <Toast />

      <DiscardModal
        show={showDiscardModal}
        onClick={handleDiscardModal}
        onClose={handleToggleDiscardModal}
      />
    </div>
  );
};

export default withAppContext(withRouter(NewMessageChat));
