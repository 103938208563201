import styled, { css } from 'styled-components';
import { Text } from '@agendaedu/ae-web-components';

import { ThemeProps } from 'config/themes/types';

import { TabSliderPropsStyle, TitlePropsStyle } from './types';

const calcTransitionTab = (widthTabs: number[], index: number): number => {
  let width = 0;
  for (let i = 0; i < index; i++) {
    width += widthTabs[i];
  }
  return width;
};

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  :hover {
    cursor: pointer;
  }
`;

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const Tab = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.xs2};
    padding: 0 ${space.xs};
  `}
`;

export const Title = styled(Text)<TitlePropsStyle>`
  ${({ currentTab, theme: { colors, font, space } }) => css`
    margin-bottom: ${space.sm};
    color: ${currentTab ? colors.brand.primary.default : colors.neutral.gray2};
    font-weight: ${currentTab && font.weight.medium};
    transition: color 0.3s ease;
  `}
`;

export const TabSlider = styled.div<TabSliderPropsStyle>`
  ${({ widthTabs, index, theme: { colors } }) => css`
    width: ${widthTabs[index]}px;
    height: 4px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    margin-bottom: -1px;
    background-color: ${colors.brand.primary.default};
    transition: 0.3s;
    transform: ${`translateX(${calcTransitionTab(widthTabs, index)}px)`};
  `}
`;

export const Wrapper = styled.div`
  ${({ theme: { colors, border } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    user-select: none;
    border-bottom: ${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray4};
  `}
`;
