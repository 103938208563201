import React, { useCallback, useContext, useEffect } from 'react';
import { Text } from '@agendaedu/ae-web-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';

import * as S from './styles';

import OmniChannelActions from 'store/messages/omniChannel/actions';
import {
  ClassroomRecipient,
  OmniChannelState,
} from 'store/messages/omniChannel/types';

import { StepLoading } from 'components/RecipientSelectList/Skeleton/StepLoading';
import { FamilyList } from './FamilyList';
import { ChatContext } from 'core/contexts/OmniChannel/Chat';

export const Family: React.FC = (): JSX.Element => {
  const { fetchNewChatFamilyRequest } = OmniChannelActions;
  const dispatch = useDispatch();

  const { handleRemoveFamily, recipients, handleAddFamily } =
    useContext(ChatContext);

  const {
    chat: {
      form: {
        channel,
        family: {
          data: familyData,
          isLoading: isLoadingTab,
          pagination: { page, totalPages },
        },
      },
    },
  } = useSelector((state: OmniChannelState) => state.omniChannel);

  const handleFetchChatFamilyRequest = useCallback(
    (currentPage: number) => {
      dispatch(fetchNewChatFamilyRequest(channel.id, currentPage));
    },
    [channel?.id, dispatch, fetchNewChatFamilyRequest]
  );

  useEffect(() => {
    if (channel?.id && !familyData?.length) handleFetchChatFamilyRequest(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel?.id]);

  return (
    <S.Wrapper id="scroll">
      <InfiniteScroll
        dataLength={familyData?.length || 0}
        next={() => handleFetchChatFamilyRequest(page + 1)}
        hasMore={page < totalPages}
        loader={<StepLoading count={1} />}
        scrollableTarget="scroll"
      >
        {isLoadingTab ? (
          <StepLoading count={4} />
        ) : (
          familyData?.map((classroom: ClassroomRecipient, index: number) => (
            <div data-testid="classroom" key={index}>
              <S.HeadquarterWrapper key={classroom.id}>
                <Text
                  mb={0}
                  paddingX="sm"
                  paddingY="lg"
                  color="neutral.gray3"
                  variant="title-bold-12"
                >
                  {classroom.name}
                </Text>
              </S.HeadquarterWrapper>

              <FamilyList
                classroom={classroom}
                selectedRecipients={recipients}
                onSelect={(student) => handleAddFamily(student)}
                onRemove={(student) => handleRemoveFamily(student)}
              />
            </div>
          ))
        )}
      </InfiniteScroll>
    </S.Wrapper>
  );
};
