import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import * as S from './styles';

import { ChatContext } from 'core/contexts/OmniChannel/Chat';

import {
  Avatar,
  Box,
  Button,
  DefaultThemeProps,
  Tag,
  Text,
} from '@agendaedu/ae-web-components';
import InputMessage from 'components/InputMessage';
import { useTheme } from 'styled-components';
import { RecipientSelection } from 'core/contexts/OmniChannel/Chat/types';
import { MessageGroupAvatar } from 'components/Messages/OmniChannel/MessageGroupAvatar';

const MessageStep = () => {
  const { t } = useTranslation(['messages', 'common']);

  const { colors } = useTheme() as DefaultThemeProps;

  const {
    setCurrentStep,
    form,
    getSelectedRecipients,
    form: {
      submitForm,
      values: { message },
    },
  } = useContext(ChatContext);

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`omni_channel.chat.${key}`, params),
    [t]
  );

  const isDisabledStep = !message || !getSelectedRecipients?.length;

  const getFormattedRecipient = (selection: RecipientSelection) =>
    ({
      student: () => ({
        avatar: {
          nameInitials: selection.student.nameInitials,
          avatarColor: selection.student.avatarColor,
          avatarUrl: selection.student.avatarUrl,
        },
        name: selection.student.name,
        kind: 'private',
        tagName: tBase(`recipients_selected.recipient_tag.${selection.tab}`, {
          recipient: selection.student.name,
          role: selection.student.role.toLowerCase(),
        }),
      }),
      responsible: () => ({
        avatar: {
          nameInitials: selection.responsible.nameInitials,
          avatarColor: selection.responsible.avatarColor,
          avatarUrl: selection.responsible.avatarUrl,
        },
        name: selection.responsible.name,
        kind: 'private',
        tagName: tBase(`recipients_selected.recipient_tag.${selection.tab}`, {
          studentName: selection.student.name,
          role: selection.responsible.role.toLowerCase(),
          responsibleName: selection.responsible.name,
        }),
      }),
      family: () => ({
        avatar: {
          nameInitials: selection.student.nameInitials,
          avatarColor: selection.student.avatarColor,
          avatarUrl: selection.student.avatarUrl,
        },
        name: selection.student.name,
        kind: 'family',
        tagName: tBase(`recipients_selected.recipient_tag.${selection.tab}`, {
          recipient: selection.student.name,
        }),
      }),
    }[selection.tab]);

  const footer = (
    <Box
      py="sm"
      position="fixed"
      display="flex"
      alignItems="center"
      left={0}
      right={0}
      bottom={0}
      px={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
      backgroundColor={colors.neutral.white}
    >
      <Button variant="secondary" onClick={() => setCurrentStep(1)}>
        {t('common:button.back')}
      </Button>

      <Button ml="auto" disabled={isDisabledStep} onClick={submitForm}>
        {t('common:button.save')}
      </Button>
    </Box>
  );

  return (
    <Box display="flex" width="100%" mx="auto" justifyContent="center" px="lg">
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        maxWidth="607px"
        py="xl3"
        height="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="sm"
          justifyContent="space-between"
          height="100%"
        >
          <Box display="flex" flexDirection="column" width="100%" gap="xl2">
            <Text
              variant="title-bold-20"
              color="neutral.black"
              fontWeight={700}
              mb={0}
            >
              {tBase('message_title')}
            </Text>

            <S.Wrapper>
              {getSelectedRecipients.map((recipientSelected, index) => {
                const { name, tagName, avatar, kind } =
                  getFormattedRecipient(recipientSelected)();

                return (
                  <S.RecipientSelecteds key={index}>
                    <Box display="flex" gap="xs" alignItems="center">
                      {kind === 'family' ? (
                        <MessageGroupAvatar {...avatar} size="xs" />
                      ) : (
                        <Avatar {...avatar} size="xs" />
                      )}

                      <Text
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        mb={0}
                        color="neutral.black"
                        variant="body-regular-14"
                      >
                        {name}
                      </Text>
                    </Box>

                    <Box display="flex" gap="xs" alignItems="center">
                      <Tag
                        name={tagName}
                        variant="neutral"
                        size="small"
                        enableEllipsis
                      />
                      {/* Será implementado futuramente
                      <S.RemoveButton>
                        <Icon name="multiply" color="black" size="sm" />
                      </S.RemoveButton> */}
                    </Box>
                  </S.RecipientSelecteds>
                );
              })}
            </S.Wrapper>
          </Box>

          <InputMessage
            message={message}
            onChange={(value) => form.setFieldValue('message', value)}
          />
        </Box>

        {footer}
      </Box>
    </Box>
  );
};

export default withRouter(MessageStep);
