import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment, { Moment } from 'moment';

import withAppContext, { appPropTypes } from 'core/hoc/withAppContext';

import Toast from 'components/Toast';
import AlbumActions from 'store/albums/actions';
import EmptyState from 'components/EmptyState';
import { IMAGES_CLASSROOMS_URL } from 'core/constants/index';
import { AlbumsListSkeleton } from './skeleton';
import { AlbumsSideFilters } from './components/AlbumsSideFilters';
import { RootState } from 'store/albums/types';
import { ActiveTermsFilters, FilterStatesProps } from 'store/filters/types';

import {
  Box,
  Button,
  FlexGrid,
  FlexGridItem,
  Select,
  SingleDate,
  Text,
  TextField,
} from '@agendaedu/ae-web-components';
import AlbumCard from './components/AlbumCard';

import * as S from './styles';

const Albums = () => {
  const { t } = useTranslation(['albums']);
  const dispatch = useDispatch();
  const [isOpenSideFilters, setIsOpenSideFilters] = useState(false);

  const { activeSchoolTerms } = useSelector(
    (state: FilterStatesProps) => state.filters
  );
  const { isLoading, albums, albumsMeta, filters, pagination } = useSelector(
    (state: RootState) => state.albums
  );
  const dataArea = useSelector(
    (state: { root: { dataArea: string } }) => state.root.dataArea
  );

  const { fetchAlbums, setFilters, setSideFiltersData } = AlbumActions;

  const displayTypesOptions = [
    { value: '', label: t('list.display_type.all_albums') },
    { value: 'not_approved', label: t('list.display_type.pending_approve') },
    {
      value: 'by_category_student',
      label: t('list.display_type.students_albums'),
    },
    {
      value: 'by_category_classroom',
      label: t('list.display_type.classrooms_albums'),
    },
  ];

  useEffect(() => {
    dispatch(setSideFiltersData());
  }, [dispatch, setSideFiltersData]);

  useEffect(() => {
    if (filters.schoolTermNames.length) dispatch(fetchAlbums({}));
  }, [dispatch, fetchAlbums, filters.schoolTermNames.length]);

  const fetchMoreAlbums = useCallback(
    () =>
      !isLoading &&
      pagination.links?.next &&
      dispatch(fetchAlbums({ nextLink: pagination.links?.next })),
    [dispatch, fetchAlbums, isLoading, pagination.links?.next]
  );

  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setFilters({ filters: { name: event.target.value } }));
  };

  const onChangeSituation = (value: string) => {
    dispatch(setFilters({ filters: { situation: value } }));
  };

  const onChangeDate = (dateField: 'startDate' | 'endDate', value: Moment) => {
    const date = value?.format('yyyy-MM-DD');

    dispatch(setFilters({ filters: { [dateField]: date } }));
  };

  const amountFilters = useMemo(() => {
    const isSelectedSchoolTermActive =
      !filters.schoolTermNames[0] ||
      activeSchoolTerms.find(
        (schoolTerm: ActiveTermsFilters) =>
          schoolTerm.value === filters.schoolTermNames[0]
      )?.isActive;

    return [
      filters.headquarterIds.some(Boolean),
      filters.educationalStageIds.some(Boolean),
      filters.classroomIds.some(Boolean),
      !isSelectedSchoolTermActive,
    ].filter(Boolean).length;
  }, [
    activeSchoolTerms,
    filters.classroomIds,
    filters.educationalStageIds,
    filters.headquarterIds,
    filters.schoolTermNames,
  ]);

  const getEmptyState = useMemo(() => {
    const emptyListMessage = [
      filters.name,
      filters.situation,
      filters.startDate,
      filters.endDate,
      amountFilters,
    ].some(Boolean)
      ? t('list.empty_state_filters')
      : t('list.empty_state');

    return (
      <EmptyState
        message={emptyListMessage}
        imgUrl={IMAGES_CLASSROOMS_URL.emptyStateUrl}
      />
    );
  }, [
    amountFilters,
    filters.endDate,
    filters.name,
    filters.situation,
    filters.startDate,
    t,
  ]);

  const list = useMemo(() => {
    if (!isLoading && !albums.length) return getEmptyState;

    if (isLoading && !albums.length) return <AlbumsListSkeleton />;

    return (
      <InfiniteScroll
        dataLength={albums.length}
        next={fetchMoreAlbums}
        hasMore={!!pagination.links?.next}
        loader={<AlbumsListSkeleton />}
        style={{ overflow: 'visible' }}
      >
        <Box display="flex" flexWrap="nowrap">
          <FlexGrid gutter="lg">
            {albums.map((album, index) => (
              <FlexGridItem
                key={album.id}
                cols={{
                  mobileXS: 12,
                  tabletMD: 6,
                  desktopLG: 4,
                  desktopXL: 3,
                }}
                filled
              >
                <AlbumCard id={`album-card-${index}`} album={album} />
              </FlexGridItem>
            ))}
          </FlexGrid>
        </Box>
      </InfiniteScroll>
    );
  }, [
    albums,
    fetchMoreAlbums,
    getEmptyState,
    isLoading,
    pagination.links?.next,
  ]);

  return (
    <Box>
      <Box mt="sm" mb="xl" mx={{ _: 'sm', desktopLG: 'xl' }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb="md"
        >
          <Text
            variant="headline-h2-bold-24"
            fontWeight="bold"
            color="neutral.black"
          >
            {t('list.title')}
          </Text>

          {albumsMeta?.canCreate && (
            <Link to={`/${dataArea}/albums/new`}>
              <S.NewAlbumButton id="new-album-button" size="md">
                {t('list.new')}
              </S.NewAlbumButton>
            </Link>
          )}
        </Box>

        <Box mt="xl">
          <Box id="filters-container">
            <FlexGrid mb="sm">
              <FlexGridItem filled cols={{ desktopLG: 3, desktopXL: 2 }}>
                <TextField
                  data-testid="search-input"
                  placeholder={t('list.search')}
                  icon="search"
                  fullWidth
                  onChange={onChangeSearch}
                />
              </FlexGridItem>

              <FlexGridItem filled cols={{ desktopLG: 3, desktopXL: 3 }}>
                <Select
                  data-testid="situation-input"
                  options={displayTypesOptions}
                  fullWidth
                  value={filters.situation}
                  onChange={(e) => onChangeSituation(e.value)}
                />
              </FlexGridItem>

              <FlexGridItem filled cols={{ desktopLG: 3, desktopXL: 2 }}>
                <SingleDate
                  id="start-date"
                  value={!!filters.startDate && moment(filters.startDate)}
                  handleChange={(date) => onChangeDate('startDate', date)}
                  fullWidth
                  placeholder={t('list.start_date')}
                  handleOutsideRange={() => false}
                />
              </FlexGridItem>

              <FlexGridItem filled cols={{ desktopLG: 3, desktopXL: 2 }}>
                <SingleDate
                  id="end-date"
                  value={!!filters.endDate && moment(filters.endDate)}
                  handleChange={(date) => onChangeDate('endDate', date)}
                  fullWidth
                  placeholder={t('list.end_date')}
                  handleOutsideRange={() => false}
                />
              </FlexGridItem>

              <FlexGridItem filled cols={{ desktopLG: 12, desktopXL: 3 }}>
                <Button
                  ml="auto"
                  icon="filter"
                  variant="secondary"
                  isOnlyIcon
                  badges={amountFilters}
                  onClick={() => setIsOpenSideFilters(true)}
                />
              </FlexGridItem>
            </FlexGrid>
          </Box>

          {!!albumsMeta?.count && (
            <Text
              id="side-filters-button"
              display="flex"
              justifyContent="end"
              variant="subtitle-medium-14"
              color="neutral.gray2"
              mt="md"
            >
              {t('list.albums_count', { count: albumsMeta?.count })}
            </Text>
          )}
        </Box>

        <Box mt="xl">{list}</Box>
      </Box>

      <AlbumsSideFilters
        isOpen={isOpenSideFilters}
        onDismiss={() => setIsOpenSideFilters(false)}
      />
      <Toast />
    </Box>
  );
};

Albums.propTypes = {
  ...appPropTypes,
};

export default withAppContext(Albums);
