import React, { useCallback, useEffect } from 'react';
import { Text } from '@agendaedu/ae-web-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';

import OmniChannelActions from 'store/messages/omniChannel/actions';
import {
  ClassroomRecipient,
  OmniChannelState,
} from 'store/messages/omniChannel/types';

import * as S from './styles';

import { Section } from './Section';
import { StepLoading } from 'components/RecipientSelectList/Skeleton/StepLoading';

export const Responsibles: React.FC = (): JSX.Element => {
  const { fetchNewChatResponsiblesRequest } = OmniChannelActions;
  const dispatch = useDispatch();

  const {
    chat: {
      form: {
        channel,
        responsibles: {
          data: responsiblesData,
          isLoading: isLoadingTab,
          pagination: { page, totalPages },
        },
      },
    },
  } = useSelector((state: OmniChannelState) => state.omniChannel);

  const handleFetchChatClassroomsRequest = useCallback(
    (currentPage: number) => {
      dispatch(fetchNewChatResponsiblesRequest(channel.id, currentPage));
    },
    [channel?.id, dispatch, fetchNewChatResponsiblesRequest]
  );

  useEffect(() => {
    if (channel?.id && !responsiblesData?.length)
      handleFetchChatClassroomsRequest(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel?.id]);

  return (
    <S.Wrapper id="scroll">
      <InfiniteScroll
        dataLength={responsiblesData?.length || 0}
        next={() => handleFetchChatClassroomsRequest(page + 1)}
        hasMore={page < totalPages}
        loader={<StepLoading count={1} />}
        scrollableTarget="scroll"
      >
        {isLoadingTab ? (
          <StepLoading count={4} />
        ) : (
          responsiblesData?.map((classroom: ClassroomRecipient, index) => (
            <div data-testid="headquarter" key={index}>
              <S.HeadquarterWrapper key={classroom.id}>
                <Text
                  mb={0}
                  paddingX="sm"
                  paddingY="lg"
                  color="neutral.gray3"
                  variant="title-bold-12"
                >
                  {classroom.name}
                </Text>
              </S.HeadquarterWrapper>

              {classroom.students.map((student, index) => (
                <Section
                  key={index}
                  recipient={student}
                  data={student?.responsibles}
                  classroomId={classroom.id}
                />
              ))}
            </div>
          ))
        )}
      </InfiniteScroll>
    </S.Wrapper>
  );
};
