import React, { useEffect, useState } from 'react';
import { Box, Radio, Text } from '@agendaedu/ae-web-components';
import { Props } from './types';
import * as S from './styles';

import useWindowDimensions from 'core/hooks/useWindowDimensions';

export const SelectQuestion = ({
  question,
  onChange,
  value,
}: Props): React.ReactElement => {
  const [selectedOptionIdValue, setSelectedOptionIdValue] = useState(value);

  const { screenWidth } = useWindowDimensions();

  const isSingleLongString = (text: string) =>
    text.length > 46 && !text.includes(' ');

  useEffect(() => {
    onChange({
      type: 'select',
      value: selectedOptionIdValue,
      questionId: question.id,
    });
  }, [question.id, selectedOptionIdValue]);

  return (
    <Box display="flex" flexDirection="column" gap="md">
      <Text variant="label-regular-14" color="neutral.gray1" marginBottom="0">
        {question.title}
      </Text>

      <Box display="flex" flexDirection="column" gap="md">
        {question.answerOptionsAttributes.map((option, index) => (
          <S.QuestionWrapper>
            <Radio
              key={`${question.id}-option-${index}`}
              onChange={() => setSelectedOptionIdValue(String(option.id))}
              checked={selectedOptionIdValue === String(option.id)}
            >
              <S.StyledText
                variant="label-regular-16"
                color="neutral.black"
                marginBottom="0"
                enableEllipsis={isSingleLongString(option.title)}
                widthDimensions={`${screenWidth - 250}px`}
              >
                {option.title}
              </S.StyledText>
            </Radio>
          </S.QuestionWrapper>
        ))}
      </Box>
    </Box>
  );
};
