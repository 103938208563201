import React, { createContext, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import {
  ContextParams,
  Props,
  RecipientSelection,
  RecipientType,
} from './types';
import {
  ChatForm,
  ResponsibleRecipient,
  StudentRecipient,
} from 'store/messages/omniChannel/types';

import omniChannelActions from 'store/messages/omniChannel/actions';
import { useDispatch } from 'react-redux';
import { UploadFilesContext } from 'core/contexts/UploadFiles';

const initialValuesInfo: ChatForm['form'] = {
  message: '',
};

export const ChatContext = createContext<Props>({
  form: { values: initialValuesInfo },
} as Props);

const ChatProvider: React.FC<ContextParams> = ({ children }) => {
  const { t } = useTranslation('messages');

  const dispatch = useDispatch();

  const { setCreateNewChatRequest } = omniChannelActions;

  const [currentStep, setCurrentStep] = useState(1);
  const [recipients, setRecipients] = useState<RecipientType>({
    classroomTab: {},
    studentTab: {},
    responsibleTab: {},
    familyTab: {},
  });

  const { selectedFiles } = useContext(UploadFilesContext);

  const getSelectedRecipients = useMemo(() => {
    const studentTab = Object.values(recipients.studentTab).reduce(
      (prevSelections: RecipientSelection[], classroomSelection) => {
        const { data, students } = classroomSelection;

        const selectedStudents = Object.keys(students).filter(
          (studentId) => students[studentId]
        );

        selectedStudents.forEach((studentId) => {
          const studentsAlreadySelected = prevSelections.find(
            (prevSelection) => prevSelection.student.id === studentId
          );
          if (!studentsAlreadySelected)
            prevSelections.push({
              tab: 'student',
              student: data[studentId],
            });
        });

        return prevSelections;
      },
      []
    );

    const responsibleTab = Object.values(recipients.responsibleTab).reduce(
      (prevSelections: RecipientSelection[], classroomSelection) => {
        const { data, responsibles } = classroomSelection;

        const selectedResponsibles = Object.keys(responsibles).filter(
          (responsibleId) => responsibles[responsibleId]
        );
        selectedResponsibles.forEach((responsibleId) => {
          const responsiblesAlreadySelected = prevSelections.find(
            (prevSelection) => prevSelection.responsible.id === responsibleId
          );

          if (!responsiblesAlreadySelected)
            prevSelections.push({
              tab: 'responsible',
              responsible: data.responsibles[responsibleId],
              student: data.student,
            });
        });

        return prevSelections;
      },
      []
    );

    const familyTab = Object.values(recipients.familyTab).reduce(
      (prevSelections: RecipientSelection[], classroomSelection) => {
        const { data, students } = classroomSelection;

        const selectedStudents = Object.keys(students).filter(
          (studentId) => students[studentId]
        );
        selectedStudents.forEach((studentId) => {
          const studentsAlreadySelected = prevSelections.find(
            (prevSelection) => prevSelection.student.id === studentId
          );

          if (!studentsAlreadySelected)
            prevSelections.push({
              tab: 'family',
              student: data[studentId],
            });
        });

        return prevSelections;
      },
      []
    );

    const classroomTab = Object.values(recipients.classroomTab).reduce(
      (prevSelections: RecipientSelection[], classroomSelection) => {
        const { data, students, classroomSendTo } = classroomSelection;

        if (classroomSendTo?.student) {
          const selectedStudents = Object.keys(students || {}).filter(
            (studentId) => students[studentId]
          );

          selectedStudents.forEach((studentId) => {
            const studentsAlreadySelected = studentTab.find(
              (prevSelection) => prevSelection.student.id === studentId
            );
            if (!studentsAlreadySelected)
              prevSelections.push({
                tab: 'student',
                student: data[studentId],
              });
          });
        }

        if (classroomSendTo?.responsible) {
          const selectedResponsibles = Object.keys(students || {}).filter(
            (studentId) => students[studentId]
          );
          selectedResponsibles.forEach((studentId) => {
            const studentData = data[studentId];

            studentData.responsibles.forEach((responsible) => {
              const responsiblesAlreadySelected = responsibleTab.find(
                (prevSelection) =>
                  prevSelection.responsible.id === responsible.id
              );

              if (!responsiblesAlreadySelected)
                prevSelections.push({
                  tab: 'responsible',
                  student: studentData,
                  responsible,
                });
            });
          });
        }

        if (classroomSendTo?.family) {
          const selectedStudents = Object.keys(students || {}).filter(
            (studentId) => students[studentId]
          );

          selectedStudents.forEach((studentId) => {
            const studentsAlreadySelected = familyTab.find(
              (prevSelection) => prevSelection.student.id === studentId
            );
            if (!studentsAlreadySelected)
              prevSelections.push({
                tab: 'family',
                student: data[studentId],
              });
          });
        }

        return prevSelections;
      },
      []
    );

    return [...classroomTab, ...studentTab, ...responsibleTab, ...familyTab];
  }, [recipients]);

  const onSubmit = (values: ChatForm['form']) => {
    dispatch(
      setCreateNewChatRequest({
        ...values,
        fileSignedId: selectedFiles?.[0]?.signedId,
        recipients: getSelectedRecipients,
      })
    );
  };

  const form = useFormik({
    initialValues: initialValuesInfo,
    onSubmit: onSubmit,
    validateOnChange: true,
    isInitialValid: false,
  });

  const handleAddStudent = (student: StudentRecipient) => {
    student.classrooms.map((classroomId: string) => {
      setRecipients((prev) => {
        const currentClassroomId = prev.studentTab[classroomId];
        return {
          ...prev,
          studentTab: {
            ...prev.studentTab,
            [classroomId]: {
              data: {
                ...currentClassroomId?.data,
                [student.id]: student,
              },
              students: {
                ...currentClassroomId?.students,
                [student.id]: true,
              },
            },
          },
        };
      });
    });
  };

  const handleRemoveStudent = (student: StudentRecipient) => {
    student.classrooms.map((classroomId: string) => {
      setRecipients((prev) => {
        const { [student.id]: _, ...rest } =
          prev.studentTab[classroomId]?.students;

        return {
          ...prev,
          studentTab: {
            ...prev.studentTab,
            [classroomId]: {
              ...prev.studentTab[classroomId],
              students: rest,
            },
          },
        };
      });
    });
  };

  const handleToggleResponsibleSelection = (
    student: StudentRecipient,
    responsible: ResponsibleRecipient
  ) => {
    student.classrooms.map((classroomId: string) => {
      setRecipients((prev) => {
        const responsibleTabSelection =
          prev.responsibleTab[`${classroomId}-${student.id}`];

        return {
          ...prev,
          responsibleTab: {
            ...prev.responsibleTab,
            [`${classroomId}-${student.id}`]: {
              data: {
                student,
                responsibles: {
                  ...responsibleTabSelection?.data?.responsibles,
                  [responsible.id]: responsible,
                },
              },
              responsibles: {
                ...responsibleTabSelection?.responsibles,
                [responsible.id]:
                  !responsibleTabSelection?.responsibles?.[responsible.id],
              },
            },
          },
        };
      });
    });
  };

  const handleAddRecipient = (
    student: StudentRecipient,
    classroomId: string
  ) => {
    setRecipients((prev) => {
      const currentClassroomId = prev.classroomTab[classroomId];
      const updatedRecipients = {
        ...prev,
        classroomTab: {
          ...prev.classroomTab,
          [classroomId]: {
            ...currentClassroomId,
            data: {
              ...currentClassroomId?.data,
              [student.id]: student,
            },
            students: {
              ...currentClassroomId?.students,
              [student.id]: true,
            },
          },
        },
      };

      return updatedRecipients;
    });
  };

  const handleRemoveRecipient = (
    student: StudentRecipient,
    classroomId: string
  ) => {
    setRecipients((prev) => {
      const { [student.id]: _, ...rest } =
        prev.classroomTab[classroomId]?.students;

      return {
        ...prev,
        classroomTab: {
          ...prev.classroomTab,
          [classroomId]: {
            ...prev.classroomTab[classroomId],
            students: rest,
          },
        },
      };
    });
  };

  const handleAddAllRecipients = (
    students: StudentRecipient[],
    classroomId: string
  ) => {
    students.map((student) => {
      setRecipients((prev) => {
        const updatedRecipients = {
          ...prev,
          classroomTab: {
            ...prev.classroomTab,
            [classroomId]: {
              ...prev.classroomTab[classroomId],
              data: {
                ...prev.classroomTab[classroomId]?.data,
                [student.id]: student,
              },
              students: {
                ...prev.classroomTab[classroomId]?.students,
                [student.id]: true,
              },
            },
          },
        };

        return updatedRecipients;
      });
    });
  };

  const handleRemoveAllRecipients = (
    students: StudentRecipient[],
    classroomId: string
  ) => {
    students.map((student) => {
      setRecipients((prev) => {
        const { [student.id]: _, ...rest } =
          prev.classroomTab[classroomId]?.students;
        return {
          ...prev,
          classroomTab: {
            ...prev.classroomTab,
            [classroomId]: {
              ...prev.classroomTab[classroomId],
              students: rest,
              classroomSendTo: {
                student: false,
                responsible: false,
                family: false,
              },
            },
          },
        };
      });
    });
  };

  const handleClassroomSendTo = (
    classroomId: string,
    sendTo: 'student' | 'responsible' | 'family'
  ) => {
    setRecipients((prev) => {
      const updatedRecipients = {
        ...prev,
        classroomTab: {
          ...prev.classroomTab,
          [classroomId]: {
            ...prev?.classroomTab[classroomId],
            classroomSendTo: {
              ...prev?.classroomTab[classroomId]?.classroomSendTo,
              [sendTo]:
                !prev?.classroomTab[classroomId]?.classroomSendTo?.[sendTo],
            },
          },
        },
      };

      return updatedRecipients;
    });
  };

  const handleAddFamily = (student: StudentRecipient) => {
    student.classrooms.map((classroomId: string) => {
      setRecipients((prev) => ({
        ...prev,
        familyTab: {
          ...prev.familyTab,
          [classroomId]: {
            data: {
              ...prev.familyTab[classroomId]?.data,
              [student.id]: student,
            },
            students: {
              ...prev.familyTab[classroomId]?.students,
              [student.id]: true,
            },
          },
        },
      }));
    });
  };

  const handleRemoveFamily = (student: StudentRecipient) => {
    student.classrooms.map((classroomId: string) => {
      setRecipients((prev) => {
        const { [student.id]: _, ...rest } =
          prev.familyTab[classroomId]?.students;

        return {
          ...prev,
          familyTab: {
            ...prev.familyTab,
            [classroomId]: {
              ...prev.familyTab[classroomId],
              students: rest,
            },
          },
        };
      });
    });
  };

  const handleRemoveAll = () => {
    setRecipients((prev) => ({
      ...prev,
      classroomTab: {},
      studentTab: {},
      responsibleTab: {},
      familyTab: {},
    }));
  };

  const isInfoStepValid = getSelectedRecipients.length > 0;

  return (
    <ChatContext.Provider
      value={{
        form,
        currentStep,
        setCurrentStep,
        recipients,
        isInfoStepValid,
        handleAddRecipient,
        handleAddStudent,
        handleRemoveRecipient,
        handleAddAllRecipients,
        handleRemoveAllRecipients,
        handleClassroomSendTo,
        handleRemoveStudent,
        handleToggleResponsibleSelection,
        handleAddFamily,
        handleRemoveFamily,
        handleRemoveAll,
        getSelectedRecipients,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;
