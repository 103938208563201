import { ClassroomRecipient } from 'store/messages/omniChannel/types';
import { ClassroomRequest, IncludedRequest } from './types';

export const normalizeChatFamily = (
  data: ClassroomRequest[],
  included: IncludedRequest[]
): ClassroomRecipient[] => {
  const includedMap = included.reduce((acc, studentInc) => {
    acc[studentInc.id] = studentInc;
    return acc;
  }, {} as Record<string, IncludedRequest>);

  return data.map((item) => {
    const students = item.relationships.studentProfiles.data
      .map((student) => {
        const foundStudent = includedMap[student.id];
        if (foundStudent) {
          return {
            channelKind: 'family' as const,
            id: foundStudent.id,
            name: foundStudent.attributes.name,
            isSelected: false,
            nameInitials: foundStudent.attributes.nameInitials,
            avatarColor: foundStudent.attributes.avatarColor,
            avatarUrl: foundStudent.attributes.avatarUrl,
            classrooms: foundStudent.attributes.classroomIds,
          };
        }
        return null;
      })
      .filter(Boolean);

    return {
      id: item.id,
      name: item.attributes.name,
      sentTo: null,
      students,
    };
  });
};
