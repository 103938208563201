import React, { useCallback, useContext } from 'react';

import { Box, Button, Tag, Text } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';

import * as S from './styles';
import { ChatContext } from 'core/contexts/OmniChannel/Chat';

export const RecipientsTagButtonList: React.FC = (): JSX.Element => {
  const { handleRemoveAll, getSelectedRecipients } = useContext(ChatContext);

  const { t } = useTranslation(['messages']);

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`omni_channel.chat.recipients_selected.${key}`, params),
    [t]
  );

  const recipientsCount = getSelectedRecipients?.length;

  if (!recipientsCount) return;

  return (
    <Box display="flex" flexDirection="column" gap="xs">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text variant="subtitle-medium-12" color="neutral.black" mb={0}>
          {tBase(recipientsCount > 1 ? 'selected' : 'select', {
            count: recipientsCount,
          })}
        </Text>

        <Button
          size="xs"
          variant="secondary"
          onClick={handleRemoveAll}
          data-testid="remove-all-button"
        >
          {tBase('remove_all_button')}
        </Button>
      </Box>
      <S.TagWrapper>
        {getSelectedRecipients.map((recipient, index) => {
          const { tab, student, responsible } = recipient;

          const currentTag = {
            student: () =>
              tBase(`recipient_tag.${tab}`, {
                recipient: student.name,
                role: student.role.toLowerCase(),
              }),
            family: () =>
              tBase(`recipient_tag.${tab}`, {
                recipient: student.name,
              }),
            responsible: () =>
              tBase(`recipient_tag.${tab}`, {
                role: responsible.role.toLowerCase(),
                studentName: student.name,
                responsibleName: responsible.name,
              }),
          }[tab];

          return <Tag size="small" name={currentTag()} key={index} />;
        })}
      </S.TagWrapper>
    </Box>
  );
};
